import React from 'react';
import { oneOf } from 'prop-types';
import AppStoreButton from 'components/buttons/AppStoreButton';
import PlayStoreButton from 'components/buttons/PlayStoreButton';
import {
  ADJUST_CAMPAIGN,
  ADJUST_ADGROUP,
  ADJUST_CREATIVE,
  PLAY_STORE_URL,
  APP_STORE_URL,
} from 'app-constants';
import { getAdjustUrl, getAdjustLoginDeeplink } from 'helpers/adjust';
import AuthStore from 'stores/AuthStore';
import useAlt from 'hooks/useAlt';

function getStoreLink(fallback, user) {
  return getAdjustUrl({
    campaign: ADJUST_CAMPAIGN.TRY_AUDIO_PAGE,
    adgroup: ADJUST_ADGROUP.WEB_AUDIO,
    creative: ADJUST_CREATIVE.BUTTON,
    fallback,
    deeplink: getAdjustLoginDeeplink(user),
  });
}

const mapState = ([{ user }]) => user;

function DownloadAppButtonComponent({ platform, ...props }) {
  const user = useAlt([AuthStore], mapState);

  if (platform === 'android') {
    return <PlayStoreButton href={getStoreLink(PLAY_STORE_URL, user)} {...props} />;
  }

  return <AppStoreButton href={getStoreLink(APP_STORE_URL, user)} {...props} />;
}

DownloadAppButtonComponent.propTypes = {
  platform: oneOf(['ios', 'android']).isRequired,
};

export default DownloadAppButtonComponent;
