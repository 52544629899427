// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (min-width: 820px){.onlySmall-On3SQ{display:none}}.hideOnSmallScreen-13exv{display:none}@media (min-width: 820px){.hideOnSmallScreen-13exv{display:inline}}\n", ""]);
// Exports
exports.locals = {
	"onlySmall": "onlySmall-On3SQ",
	"hideOnSmallScreen": "hideOnSmallScreen-13exv"
};
module.exports = exports;
