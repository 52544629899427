// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes pulse-1KNif{from{transform:translate3d(-50%, -50%, 0) scale(0);opacity:1}to{transform:translate3d(-50%, -50%, 0) scale(1);opacity:0}}.page-MAcsa{background:white;padding:24px 0 64px;max-width:610px;margin:0 auto}@media (min-width: 820px){.page-MAcsa{min-height:calc(100vh - 66px);max-width:1026px;display:flex;align-items:center;justify-content:space-between}}.horizontalPadding-3bK3Q{padding:0 16px}@media (min-width: 820px){.textPane-iK_wM{max-width:528px}}.storeButton-3Jqrl{display:inline-block;height:40px;width:auto}.storeButtonContainer-YkEc6{display:flex;justify-content:center}@media (min-width: 820px){.storeButtonContainer-YkEc6{justify-content:flex-start}}.deviceWrapper-KnoJP{flex:1 0 auto;display:flex;justify-content:center;align-items:center;margin-top:40px}.pulseRings-QQDcr{position:absolute;width:500px;height:500px}.pulseRings-QQDcr :nth-child(2){animation-delay:1s}.pulseRing-2kWHz{position:absolute;background:rgba(255,14,97,0.1);opacity:0;left:50%;top:50%;border-radius:100%;border:2px solid #ff1060;transform:translate3d(-50%, -50%, 0);width:500px;height:500px;animation:pulse-1KNif 3s ease-out infinite}\n", ""]);
// Exports
exports.locals = {
	"page": "page-MAcsa",
	"horizontalPadding": "horizontalPadding-3bK3Q",
	"textPane": "textPane-iK_wM",
	"storeButton": "storeButton-3Jqrl",
	"storeButtonContainer": "storeButtonContainer-YkEc6",
	"deviceWrapper": "deviceWrapper-KnoJP",
	"pulseRings": "pulseRings-QQDcr",
	"pulseRing": "pulseRing-2kWHz",
	"pulse": "pulse-1KNif"
};
module.exports = exports;
