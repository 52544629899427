import React from 'react';

import styledModule from 'helpers/styledModule';
import { DeviceFrame } from '@blendle/lego';
import { Video } from 'components/Video';
import resetStyles from 'higher-order-components/resetStyles';
import useEventOnMount from 'hooks/useEventOnMount';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import CSS from './style.scss';
import { H1, H2, Body, Detail } from '../Typography';
import { SmallScreenOnly, HideOnSmallScreen } from '../Breakpoints';
import DownloadAppButton from '../DownloadAppButton';
import BadgeNew from '../BadgeNew';
import SendDownloadLink from '../SendDownloadLink';

const Page = styledModule.div(CSS.page);
const TextPane = styledModule.div(CSS.textPane);
const DevicePane = styledModule.div();
const HorizontalPadding = styledModule.div(CSS.horizontalPadding);
const StoreButtonContainer = styledModule.div(CSS.storeButtonContainer);
const StyledDownloadAppButton = styledModule(DownloadAppButton)(CSS.storeButton);

const DeviceWrapper = styledModule.div(CSS.deviceWrapper);
const PulseRings = styledModule.div(CSS.pulseRings);
const PulseRing = styledModule.div(CSS.pulseRing);

/**
 * Returns true if the current device has an app which supports Blendle Audio
 * @param {String} device Device name
 */
function deviceHasAudioApp(device) {
  return ['Android', 'iPod', 'iPhone', 'iPad'].includes(device);
}

function mapPlatform(device) {
  return device === 'Android' ? 'android' : 'ios';
}

function renderTopCTA(device) {
  if (deviceHasAudioApp(device)) {
    return (
      <StoreButtonContainer>
        <StyledDownloadAppButton platform={mapPlatform(device)} />
      </StoreButtonContainer>
    );
  }

  return <SendDownloadLink />;
}

function renderBottomCTA(device) {
  if (deviceHasAudioApp(device)) {
    return (
      <SmallScreenOnly>
        <HorizontalPadding>
          <Body>Download nu de app en luister direct naar de beste stukken van de&nbsp;dag!</Body>
          <StoreButtonContainer>
            <StyledDownloadAppButton platform={mapPlatform(device)} />
          </StoreButtonContainer>
        </HorizontalPadding>
      </SmallScreenOnly>
    );
  }

  return null;
}

function TryAudioPageComponent() {
  const { device } = window.BrowserDetect;

  useEventOnMount('View Audio Page');
  usePageEventOnMount({ pageName: 'Audio' });

  return (
    <Page>
      <TextPane>
        <HorizontalPadding>
          <BadgeNew />
          <H1>Lekker luisteren in de&nbsp;app</H1>
          <H2>
            Even geen tijd om te lezen? De mooiste stemmen lezen je de interessantste
            artikelen&nbsp;voor.
          </H2>
          {renderTopCTA(device)}
        </HorizontalPadding>
      </TextPane>
      <DevicePane>
        <DeviceWrapper>
          <PulseRings>
            <PulseRing />
            <PulseRing />
          </PulseRings>
          <DeviceFrame device="iPhoneX">
            <Video
              poster="https://publication.blendleimg.com/videos/features/ios_audio.jpg?auto=compress,format&fit=clip&w=265"
              src="https://static.blendle.nl/videos/features/ios_audio.mp4"
            />
          </DeviceFrame>
        </DeviceWrapper>
        <HideOnSmallScreen>
          <Detail>(Geen zorgen, er is ook een Android-app)</Detail>
        </HideOnSmallScreen>
        {renderBottomCTA(device)}
      </DevicePane>
    </Page>
  );
}

export default resetStyles(TryAudioPageComponent);
