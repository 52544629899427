// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".badge-2oE28{font-family:\"GT-Walsheim\",sans-serif;font-size:16px;letter-spacing:-0.02em;color:#ff1060;text-transform:uppercase;font-weight:500;display:flex;align-items:center;justify-content:center;margin-bottom:20px}@media (min-width: 820px){.badge-2oE28{justify-content:flex-start}}.iconContainer-2aVkg{width:48px;height:48px;border-radius:100px;background-color:rgba(255,16,96,0.08);margin-right:16px;display:flex;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"badge": "badge-2oE28",
	"iconContainer": "iconContainer-2aVkg"
};
module.exports = exports;
