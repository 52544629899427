import React from 'react';
import { number, func } from 'prop-types';
import { STATUS_PENDING, STATUS_OK, STATUS_ERROR, INTERNAL_LOCATION } from 'app-constants';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import User from 'components/User';
import { email } from 'selectors/user';
import useSendDownloadAppLink from 'hooks/useSendDownloadAppLink';
import { equals } from 'ramda';
import { Body } from '../Typography';
import CSS from './style.scss';

const SendEmailContainer = styledModule.div(CSS.sendEmailContainer);
const ErrorMessage = styledModule(Body)(CSS.error);

function SendEmailButton({ status, requestEmail }) {
  return (
    <Button color="razzmatazz" onClick={requestEmail} isLoading={status === STATUS_PENDING}>
      Stuur download-link
    </Button>
  );
}

SendEmailButton.propTypes = {
  status: number.isRequired,
  requestEmail: func.isRequired,
};

function ErrorState({ status, requestEmail }) {
  return (
    <SendEmailContainer>
      <ErrorMessage>Oef! Er ging iets mis. Probeer het anders nog&nbsp;eens?</ErrorMessage>
      <SendEmailButton status={status} requestEmail={requestEmail} />
    </SendEmailContainer>
  );
}

ErrorState.propTypes = {
  status: number.isRequired,
  requestEmail: func.isRequired,
};

function SuccessState() {
  return (
    <SendEmailContainer>
      <Body>Gelukt! De email is via de information-cyber-highway naar je&nbsp;onderweg.</Body>
    </SendEmailContainer>
  );
}

const isOk = equals(STATUS_OK);
const isError = equals(STATUS_ERROR);

function SendDownloadLink() {
  const { downloadAppLinkStatus, sendDownloadAppLink } = useSendDownloadAppLink(
    INTERNAL_LOCATION.AUDIO_PAGE,
  );

  if (isOk(downloadAppLinkStatus)) {
    return <SuccessState />;
  }

  if (isError(downloadAppLinkStatus)) {
    return <ErrorState status={downloadAppLinkStatus} requestEmail={sendDownloadAppLink} />;
  }

  return (
    <SendEmailContainer>
      <User>
        {user => (
          <Body>
            Als je wilt, sturen we een download-linkje naar <strong>{email(user)}</strong>
          </Body>
        )}
      </User>
      <SendEmailButton status={downloadAppLinkStatus} requestEmail={sendDownloadAppLink} />
    </SendEmailContainer>
  );
}

export default SendDownloadLink;
