import React from 'react';
import styledModule from 'helpers/styledModule';
import AudioIcon from 'components/icons/Audio';
import CSS from './style.scss';

const Badge = styledModule.div(CSS.badge);
const IconContainer = styledModule.div(CSS.iconContainer);

function BadgeNewComponent() {
  return (
    <Badge>
      <IconContainer>
        <AudioIcon width="22" height="auto" />
      </IconContainer>
      <p>Blendle Audio</p>
    </Badge>
  );
}

export default BadgeNewComponent;
