// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sendEmailContainer-3sTv4{max-width:320px;margin:32px auto;align-items:center;display:flex;flex-direction:column}@media (min-width: 820px){.sendEmailContainer-3sTv4{margin:88px auto 0 0;align-items:flex-start}}.error-1jhAu{color:#e4300b}\n", ""]);
// Exports
exports.locals = {
	"sendEmailContainer": "sendEmailContainer-3sTv4",
	"error": "error-1jhAu"
};
module.exports = exports;
